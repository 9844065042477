import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubSquare, faFacebookSquare, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";

const FooterContainer = styled.footer`
  position: fixed;
  bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1; /* Asegura que el footer esté por encima */
  position: relative; /* Necesario para que z-index funcione */
`;

const IconContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;

  @media (max-width: 1200px) {
    gap: 0.75rem; /* Ajustar el espacio entre iconos en laptops */
  }

  @media (max-width: 900px) {
    gap: 0.5rem; /* Ajustar el espacio entre iconos en tablets */
  }

  @media (max-width: 600px) {
    gap: 0.5rem; /* Ajustar el espacio entre iconos en dispositivos móviles */
  }
`;

const IconLink = styled.a`
  color: ${({ theme }) => theme.emailColor}; /* Utiliza la paleta de colores configurada */
  font-size: 2rem;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.emailColor}; /* Mantener el mismo color al pasar el cursor */
  }

  @media (max-width: 1200px) {
    font-size: 1.75rem; /* Ajustar el tamaño de los iconos en laptops */
  }

  @media (max-width: 900px) {
    font-size: 1.5rem; /* Ajustar el tamaño de los iconos en tablets */
  }

  @media (max-width: 600px) {
    font-size: 1.25rem; /* Ajustar el tamaño de los iconos en dispositivos móviles */
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Alinear el texto a la izquierda */
  padding-left: 3rem; /* Agregar padding para dar espacio en la izquierda */
`;

const Text = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.textColor};

  @media (max-width: 1200px) {
    font-size: 0.95rem; /* Ajustar el tamaño del texto en laptops */
  }

  @media (max-width: 900px) {
    font-size: 0.9rem; /* Ajustar el tamaño del texto en tablets */
  }

  @media (max-width: 600px) {
    font-size: 0.875rem; /* Ajustar el tamaño del texto en dispositivos móviles */
  }
`;

export const Footer = () => (
  <FooterContainer>
    <IconContainer>
      <IconLink href="https://github.com/albertapiadev" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithubSquare} />
      </IconLink>
      <IconLink href="https://www.facebook.com/albertapiadev" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookSquare} />
      </IconLink>
      <IconLink href="https://www.instagram.com/albertapiadev" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagramSquare} />
      </IconLink>
    </IconContainer>
    <TextContainer>
      <Text>2024 © Albert Tapia</Text>
    </TextContainer>
  </FooterContainer>
);
