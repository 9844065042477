import React, { ChangeEvent, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import MaterialUISwitch from "./MaterialUISwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";

const ToggleContainer = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    toggleTheme();
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '1rem',
        right: '1rem',
        zIndex: 2, /* Asegura que el botón esté por encima */
      }}
    >
      <FormControlLabel
        control={
          <MaterialUISwitch
            checked={theme.name === "dark"}
            onChange={handleToggle}
          />
        }
        label=""
      />
    </Box>
  );
};

export default ToggleContainer;
