import { createContext, ReactNode, useEffect, useState } from "react";

interface Theme {
  name: string;
  backgroundColor: string;
  textColor: string;
  emailColor: string; // Añadir el color del correo electrónico
}

const darkTheme: Theme = {
  name: "dark",
  backgroundColor: "#000000",
  textColor: "#fbffcd",
  emailColor: "#79ae92", // Color para el modo oscuro
};

const lightTheme: Theme = {
  name: "light",
  backgroundColor: "#fbffcd",
  textColor: "#1c5560",
  emailColor: "#001f36", // Color para el modo claro
};

interface ThemeContextProps {
  theme: Theme;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextProps>({
  theme: lightTheme,
  toggleTheme: () => {},
});

const getInitialTheme = (): Theme => {
  const savedTheme = localStorage.getItem("theme");
  if (savedTheme === "dark") {
    return darkTheme;
  }
  if (savedTheme === "light") {
    return lightTheme;
  }
  const userPrefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  return userPrefersDark ? darkTheme : lightTheme;
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<Theme>(getInitialTheme());

  const toggleTheme = () => {
    const newTheme = theme.name === "dark" ? lightTheme : darkTheme;
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme.name);
  };

  useEffect(() => {
    document.body.style.backgroundColor = theme.backgroundColor;
    document.body.style.color = theme.textColor;
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
