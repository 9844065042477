import { useEffect, useMemo, useState, useContext } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { ThemeContext } from "../context/ThemeContext";
import { particlesConfig } from "../appearance/particlesConfig";
import { ISourceOptions, Container } from "@tsparticles/engine";
import styled from "styled-components";

const ParticlesContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1; /* Asegura que las partículas estén en el fondo */
`;

export const ParticlesBackground = () => {
  const { theme } = useContext(ThemeContext);
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options: ISourceOptions = useMemo(() => {
    const particlesOptions = particlesConfig.particles || {};
    const linksOptions = particlesOptions.links || {};

    return {
      ...particlesConfig,
      background: {
        color: {
          value: theme.name === "dark" ? "#000000" : "#fbffcd",
        },
      },
      particles: {
        ...particlesOptions,
        links: {
          ...linksOptions,
          color: theme.name === "dark" ? "#ffffff" : "#1c5560", // Cambiar el color de las líneas según el tema
        },
      },
    };
  }, [theme]);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    console.log(container);
  };

  if (init) {
    return (
      <ParticlesContainer>
        <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />
      </ParticlesContainer>
    );
  }

  return <></>;
};
