import React from "react";
import styled from "styled-components";
import LottieAnimation from "./LottieAnimation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  z-index: 1; /* Asegura que el contenido esté por encima */
  position: relative; /* Necesario para que z-index funcione */
  color: ${({ theme }) => theme.textColor};
  padding: 1rem; /* Agregar padding para evitar que el texto se corte */

  @media (max-width: 1200px) {
    padding: 1rem; /* Asegurar que haya espacio en los extremos en laptops */
  }

  @media (max-width: 900px) {
    padding: 1rem; /* Asegurar que haya espacio en los extremos en tablets */
  }

  @media (max-width: 600px) {
    padding: 1rem; /* Asegurar que haya espacio en los extremos en dispositivos móviles */
  }
`;

const Title = styled.h1`
  font-family: "Bebas Neue", cursive;
  font-size: 4rem;
  margin: 0;

  @media (max-width: 1200px) {
    font-size: 3.5rem; /* Ajustar el tamaño del título en laptops */
  }

  @media (max-width: 900px) {
    font-size: 3rem; /* Ajustar el tamaño del título en tablets */
  }

  @media (max-width: 600px) {
    font-size: 2.5rem; /* Ajustar el tamaño del título en dispositivos móviles */
  }
`;

const Text = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1rem; /* Ajustar el tamaño del texto */
  margin: 0.2rem 0;

  @media (max-width: 1200px) {
    font-size: 0.95rem; /* Ajustar el tamaño del texto en laptops */
  }

  @media (max-width: 900px) {
    font-size: 0.9rem; /* Ajustar el tamaño del texto en tablets */
  }

  @media (max-width: 600px) {
    font-size: 0.875rem; /* Ajustar el tamaño del texto en dispositivos móviles */
  }
`;

const EmailLink = styled.a`
  font-weight: bold;
  color: ${({ theme }) => theme.emailColor}; /* Utiliza la paleta de colores configurada */
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.emailColor}; /* Mantener el mismo color al pasar el cursor */
  }
`;

export const Content = () => (
  <Container>
    <LottieAnimation /> {/* Añadir la animación Lottie aquí */}
    <Title>Sitio en Construcción</Title>
    <Text>Mi portafolio personal estará disponible pronto.</Text>
    <Text>
      Para consultas, por favor contáctame en{" "}
      <EmailLink href="mailto:support@albertapia.dev" target="_blank">
      support@albertapia.dev
      </EmailLink>
    </Text>
    <Text>¡Gracias por tu paciencia!</Text>
  </Container>
);
