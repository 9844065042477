import React from "react";
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from "styled-components";
import { ThemeProvider, ThemeContext } from "./context/ThemeContext";
import { ParticlesBackground } from "./components/ParticlesBackground";
import Toggle from "./components/Toggle"; // Importación predeterminada
import { Content } from "./components/Content";
import { Footer } from "./components/Footer";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    transition: all 0.5s ease;
    font-family: 'Roboto', sans-serif;
    overflow: hidden; /* Evitar el scrollbar vertical */
  }
  #root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
`;

const App = () => {
  return (
    <ThemeProvider>
      <ThemeContext.Consumer>
        {({ theme }) => (
          <StyledThemeProvider theme={theme}>
            <GlobalStyle />
            <ParticlesBackground />
            <Toggle /> {/* Uso del componente predeterminado */}
            <Content />
            <Footer />
          </StyledThemeProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeProvider>
  );
};

export default App;
